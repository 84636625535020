import React, { useEffect, useRef, useState } from 'react';
import { graphql, Link } from 'gatsby';

import Layout from '../../layout/Layout';
import Seo from '../../components/Seo';
import Nav from '../../components/Nav/Nav';
import ImageCreation from '../../components/Image/ImageCreation/ImageCreation'


const Creations = ({ data }) => {

  const [slice, setSlice] = useState(3);
  const creationRef = useRef();
  const pageData = data?.allContentfulPageListCreation?.nodes[0];

  const creationLength = pageData?.creations.length;
  
  useEffect(() => {
    window.addEventListener("scroll", updateSlice);
    return () => {
      window.removeEventListener("scroll", updateSlice);
    }
  })

  const updateSlice = () => {
    if(window.scrollY + window.innerHeight > creationRef?.current?.offsetHeight) {
      if(creationLength > slice){
        setSlice(slice + 3);
      }
    }
  }
  
  return (
    <Layout>
      <Seo
        lang="fr"
        title={pageData.title || 'Creations - Publicis et Nous'}
        description={pageData.description || 'Publicis et Nous'}
      />
      <div id="main-wrapper" className="row">
        <Nav />
        <section id="container" className=" creations col-lg-9 col-md-12">
          <div className="project-box" ref={creationRef}>
            {
              pageData?.creations?.slice(0, slice).map((creationItem, index) => (
                <div className={`content`} key={index}>
                  <Link to={'/creations/'+creationItem.slug.replaceAll('_', '-')}>
                    <ImageCreation img={creationItem.visuelThumbnail?.gatsbyImageData} alt={creationItem.creationName}/>
                  </Link>
                  <div className="content-creations col-lg-12">
                    <div className="info">
                      <Link to={'/creations/'+creationItem.slug.replaceAll('_', '-')}>{creationItem.creationDescription}</Link>
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        </section>
      </div>
    </Layout>
  );
};

export const query = graphql`
  {
    allContentfulPageListCreation {
      nodes{
        title
        description
        creations{
          slug
          creationName
          creationDescription
          visuelThumbnail {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`

export default Creations;
